












import { computed, defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import RichText from '~/cms/RichText.vue';

export default defineComponent({
  name: 'RichTextHighlights',
  components: {
    RichText,
  },
  props: {
    rows: {
      type: Array as PropType<Array<{ richText: string; external?: string }>>,
      default: () => [],
    },
  },
  setup(props) {
    const parsedRows = computed(() => {
      if (!props.rows) {
        return [];
      }
      return props.rows.map(row => ({
        ...row,
        richText: row.richText,
      }));
    });

    return {
      parsedRows,
    };
  },
});
