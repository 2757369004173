







import { computed, defineComponent } from '@nuxtjs/composition-api';
import parseMarkdown from '~/helpers/parseMarkdown';
import DisplayHtmlDangerously from '~/components/atoms/DisplayHtmlDangerously/DisplayHtmlDangerously.vue';

export default defineComponent({
  name: 'RichText',
  components: {
    DisplayHtmlDangerously,
  },
  props: {
    richText: {
      type: String,
      default: () => '',
    },
  },
  setup(props) {
    const markdownText = computed(() => parseMarkdown(props.richText));

    return {
      markdownText,
    };
  },
});
